
import {
  defineComponent,
  reactive,
  onBeforeMount,
  watch,
  onMounted,
  ref,
} from "vue";
import { VideoPause, VideoPlay } from "@element-plus/icons";
// import { durationToFormat } from "@/utils/refactor";
import moment from "moment";

export default defineComponent({
  name: "GloweAudio",
  components: {
    VideoPlay,
    VideoPause,
  },
  props: {
    audioUrl: {
      type: String,
      required: true,
    },
    musicTitle: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const circleRef = ref();
    const sliderbarRef = ref();
    const audioData = reactive({
      audiourl: props.audioUrl,
      playing: false,
      duration: 0, // 音频总时长
      currentTime: 0, // 当前播放的位置
    });
    let audioEl: HTMLAudioElement | null = null;
    const wrapEle: {
      width: string;
      el: any;
    } = {
      width: "0px",
      el: null,
    };
    const sliderBar: {
      width: string;
      el: any;
    } = {
      width: "0%",
      el: null,
    };
    const circleTarget: {
      circleMouseMouve: boolean;
      pageX: number;
      circleLeft: string;
      el: any;
    } = {
      circleMouseMouve: false,
      pageX: 0,
      circleLeft: "0%",
      el: null,
    };
    let circleDragLeft = "0%"; // 圆圈被鼠标水平拖拽的距离（默认向左）

    const mousemove = (e: any) => {
      if (circleTarget.circleMouseMouve) {
        const nowLeft =
          parseFloat(circleTarget.circleLeft) +
          getPercentage(e.pageX - circleTarget.pageX || 0, wrapEle.width);

        if (nowLeft >= 100) {
          circleDragLeft = "100%";
        } else if (nowLeft <= 0) {
          circleDragLeft = "0%";
        } else {
          circleDragLeft = `${nowLeft}%`;
        }

        updateProgressBar(circleDragLeft);
        currentTimeByProgressBar(circleDragLeft);
      }
    };
    document.addEventListener("mousemove", (e) => {
      mousemove(e);
    });
    document.addEventListener("touchmove", (e) => {
      mousemove(e.targetTouches[0]);
    });
    document.addEventListener("mouseup", () => {
      circleTarget.circleMouseMouve = false;
    });
    document.addEventListener("touchend", () => {
      circleTarget.circleMouseMouve = false;
    });
    const circleMousedown = (e: any) => {
      if (audioEl) {
        // 拖动进度条歌曲暂停播放
        audioPause();
      }
      circleTarget.el = e.target; // 圆圈自身
      wrapEle.el = e.target.parentElement; // 圆圈父元素
      sliderBar.el = e.target.nextElementSibling; // 圆圈的兄弟节点

      circleTarget.circleLeft = e.target.style.left;
      circleTarget.pageX = e.pageX ?? e.targetTouches[0].pageX;
      circleTarget.circleMouseMouve = true;
      wrapEle.width = window
        .getComputedStyle(wrapEle.el, null)
        .getPropertyValue("width");
    };
    // 只处理e.target是slider-wrap 或 slider-bar的情况
    const clickSliderWrap = (e: any) => {
      if (audioEl) {
        // 拖动进度条结束歌曲开始播放
        audioPlay();
      }
      if (e.target.getAttribute("target") === "wrap") {
        wrapEle.el = e.target;
        circleTarget.el = e.target.firstElementChild;
        sliderBar.el = e.target.lastElementChild;
      } else if (e.target.getAttribute("target") === "sliderbar") {
        sliderBar.el = e.target;
        circleTarget.el = e.target.previousElementSibling;
        wrapEle.el = e.target.parentElement;
      } else {
        return;
      }
      wrapEle.width = window
        .getComputedStyle(wrapEle.el, null)
        .getPropertyValue("width");

      const styleLeft = `${getPercentage(e.offsetX, wrapEle.width)}%`;
      updateProgressBar(styleLeft);
      currentTimeByProgressBar(styleLeft);
    };

    // 播放或暂停音频
    const playPauseAudio = (e: any) => {
      const iconDiv = findParentsEl(e.target.parentElement, "icon-div");
      wrapEle.el = iconDiv?.nextElementSibling;
      circleTarget.el = wrapEle.el.firstElementChild;
      sliderBar.el = wrapEle.el.lastElementChild;
      const parentAudio = findParentsEl(e.target.parentElement, "audio");
      if (parentAudio) {
        if (!audioData.playing) {
          audioPlay();
        } else {
          audioPause();
        }
      }
    };

    // 计算百分比的分子
    function getPercentage(num: number | string, den: number | string): number {
      const numerator = typeof num === "number" ? num : parseFloat(num);
      const denominator = typeof den === "number" ? den : parseFloat(den);
      return Math.round((numerator / denominator) * 10000) / 100;
    }
    // 查找自身或最近的一个父元素有className的
    function findParentsEl(
      el: HTMLElement,
      classname: string
    ): HTMLElement | null {
      // 注意avg className得到一个对象而非字符串
      if (
        el &&
        el.className?.split &&
        el.className.split(" ").includes(classname)
      ) {
        return el;
      }
      if (el.parentElement) {
        if (el.parentElement.className.split(" ").includes(classname)) {
          return el.parentElement;
        } else {
          return findParentsEl(el.parentElement, classname);
        }
      }
      return null;
    }
    /**
     * 更新进度条
     * @param percentage 得到一个百分比的字符串
     */
    function updateProgressBar(percentage: string) {
      circleTarget.el.style.left = percentage;
      sliderBar.el.style.width = percentage;
    }

    /**
     * 以下是对音频的操作
     */

    // 音频播放结束
    const audioEnded = () => {
      audioData.playing = false;
    };
    // 播放进度：表示audio正在播放，currentTime在更新
    const audioTimeupdate = (e: any) => {
      audioData.currentTime = e.target.currentTime;
      progressBarBycurrentTime();
    };
    // 当媒体音频第一帧加载完成时
    const audioLoadeddata = (e: any) => {
      audioEl = e.target;
      audioData.duration = e.target.duration;
      audioPlay();
    };

    // 播放
    function audioPlay() {
      if (audioEl) {
        audioEl.play();
        audioData.playing = true;
      }
    }

    // 暂停播放
    function audioPause() {
      if (audioEl) {
        audioEl.pause();
        audioData.playing = false;
      }
    }

    // 进度条和音频播放进度进行关联
    function progressBarBycurrentTime() {
      const progress = getPercentage(audioData.currentTime, audioData.duration);
      updateProgressBar(`${progress}%`);
    }
    /**
     * 播放进度与进度条进行关联
     * @param stylePercentage 圆圈的left值
     */
    function currentTimeByProgressBar(styleLeft: string) {
      if (audioEl) {
        // audioPause();
        const currentTime = (parseFloat(styleLeft) / 100) * audioData.duration;
        audioEl.currentTime = currentTime;
        audioData.currentTime = currentTime;
      }
    }

    const durationFormat = (num: number): string => {
      const time = moment.duration(num, "seconds");
      const hours = time.hours();
      const minutes = time.minutes();
      const seconds = time.seconds();
      return moment({ h: hours, m: minutes, s: seconds }).format("HH:mm:ss");
    };
    const durationToWidth = (num: number): string => {
      return `${Math.ceil((158 / 58) * num + 33)}px`;
    };
    watch(
      () => props.audioUrl,
      () => {
        audioPause();
      }
    );
    onMounted(() => {
      circleTarget.el = circleRef.value;
      sliderBar.el = sliderbarRef.value;
      // audioPlay();
    });
    return {
      sliderbarRef,
      circleRef,
      props,
      audioData,
      circleMousedown,
      clickSliderWrap,
      playPauseAudio,
      audioEnded,
      audioTimeupdate,
      audioLoadeddata,
      durationFormat,
      durationToWidth,
    };
  },
});
